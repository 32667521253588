import { get as _get, post as _post, put as _put, del as _del } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { isProduction } from "src/config/env";
import { isString } from "src/utils/strings";
type path =
  | "user"
  | "auth"
  | "outdefine_hire"
  | "outdefine_hire/client"
  | "outdefine_hire/client_invite"
  | "outdefine_hire/client_invite/myinvites"
  | "outdefine_hire/company"
  | "outdefine_hire/job"
  | "outdefine_hire/interview"
  | "outdefine_hire/interview/metrics"
  | "outdefine_hire/interview/reminder"
  | "outdefine_hire/interview/generate-feedback";

const getAuthHeader = async () => {
  try {
    const session = await fetchAuthSession({ forceRefresh: true }); // Fetch the authentication session
    return { Authorization: session.tokens?.accessToken.toString() };
  } catch (e) {
    console.error("Error fetching session: ", e);
    return null;
  }
};
const apiName = !isProduction ? "dev-outdefine-app-backend" : "prod-outdefine-app-backend";

interface IParams {
  suffix?: string;
  path: path;
  autoError?: boolean; // true if you want status Code errors to be handled automatically
  queryParams?: object;
  retries?: number;
}

interface IPost extends IParams {
  body?: any;
}

const middleware = async (params: IParams, callback: (data) => any) => {
  const { path, suffix, autoError, queryParams, retries = 1 } = params;
  let _path = `/${path}`;
  if (suffix) _path += "/" + suffix;
  if (queryParams) _path += "?" + getQueryParamsPath(queryParams);

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const authHeader = await getAuthHeader();
      const options = { headers: { ...authHeader } };
      const op = callback({ path: _path, apiName, options });
      const result = await op.response;
      if (autoError && result.statusCode !== 200)
        throw new Error("Something went wrong with the request");
      return {
        body: (await result.body.json()) as any
      };
    } catch (e) {
      console.error(`Network Error on path ${_path}, attempt ${attempt + 1} of ${retries}`);
      if (attempt === retries - 1) throw e;
    }
  }
  throw new Error("Request failed after maximum retries");
};

export async function get(params: IParams) {
  return await middleware(params, (data) => _get(data));
}

export async function post(params: IPost) {
  const body = params.body;
  delete params.body;
  return await middleware(params, (data) => {
    data.options.body = JSON.stringify(body);
    return _post(data);
  });
}

export async function put(params: IPost) {
  const body = params.body;
  delete params.body;
  return await middleware(params, (data) => {
    data.options.body = JSON.stringify(body);
    return _put(data);
  });
}

export async function del(params: IPost) {
  const body = params.body;
  delete params.body;
  return await middleware(params, (data) => {
    data.options.body = JSON.stringify(body);
    return _del(data);
  });
}

// Utility to convert object into query params string
export const getQueryParamsPath = (filters: object) => {
  // Add filters params
  let path = "";
  Object.keys(filters).forEach((key) => {
    let value = filters[key];
    value = isString(value) ? value : JSON.stringify(value);
    path += `${key}=${value}&`;
  });
  return path;
};
