import React from "react";
import LogRocket from "logrocket";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Page500 from "./pages/errors/Page500";
import { store } from "./redux/store";
import { setCookie } from "./utils/storage";
import { isLocal } from "./config/env";

if (!isLocal) LogRocket.init("roaypk/outdefine-hire");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Save the initial redirect_url
setCookie("redirect_url", location.pathname + location.search, { days: 1 });

root.render(
  <>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={Page500}>
        <App />
      </ErrorBoundary>
    </Provider>
    <Toaster />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
