import { useEffect, useState } from "react";
import { PaginationParams, PaginationResults } from "src/types";
import { customToast } from "src/utils/toast";

interface IUsePagination<T, FILTER> {
  fetchPage: (params: PaginationParams & any) => Promise<PaginationResults<T>>;
  page_size: number;
  filters?: FILTER;
}

/**
 * This Hook can be used for handling most of the pagination fetching data
 */
function usePagination<T, FILTER = any>({
  fetchPage,
  page_size = 20,
  filters
}: IUsePagination<T, FILTER>) {
  const [data, setData] = useState<T[]>();
  const [count, setCount] = useState<number>();
  const [fetching, setFetching] = useState<boolean>();

  const fetch = async (count = false) => {
    if (fetching) return;
    setFetching(true);
    try {
      const result = await fetchPage({
        count,
        offset: data?.length || 0,
        page_size,
        ...filters
      });
      if (result.count !== undefined) setCount(result.count);
      setData([...(data || []), ...result.data]);
    } catch (e) {
      customToast({ message: "Error fetching page" });
    }
    setFetching(false);
  };

  // Refresh (if filters change, fetch changes too)
  useEffect(() => {
    setCount(undefined);
    setData(undefined);
  }, [filters]);

  // No data is the signal to fetch
  useEffect(() => {
    if (!data) fetch(true);
  }, [data]);

  return { fetching, count, data, fetch };
}

export default usePagination;
