import { useNavigate } from "react-router-dom";
import { CountCard } from "../../components/CountCard";
import Empty from "../../components/Empty";
import usePagination from "src/hooks/usePagination";
import { Interview } from "src/types";
import { getInterviewsPaginated, sendReminder } from "src/network/interview";
import PaginatedDiv from "src/components/PaginatedDiv";
import Table from "src/components/Tables/Table";
import Button from "src/components/Button";
import CopyButton from "src/components/CopyButton";
import { dateFormat1 } from "src/utils/dates/formats";
import InterviewStatusTag from "../../components/InterviewStatusTag";
import { InterviewMetrics } from "src/network/interview/type";
import { useState } from "react";
import { customToast } from "src/utils/toast";
import useSlugParam from "src/hooks/useSlug";
import LoaderPage from "src/components/Loader/LoaderPage";
import InitialsLogo from "src/components/InitialsLogo";
import Job from "src/types/models/Job";

const InvitedTalent = ({ metrics, job }: { metrics?: InterviewMetrics; job: Job }) => {
  const navigate = useNavigate();
  const { id, slug } = useSlugParam();
  const { data, count, fetching, fetch } = usePagination<Interview>({
    fetchPage: (d) => getInterviewsPaginated({ ...d, job_id: id, status: "INVITED" }),
    page_size: 10
  });
  // Reminder email handling
  const [reminding, setReminding] = useState<number>();
  const remind = async (id: number) => {
    setReminding(id);
    try {
      await sendReminder({ interview_id: id });
      customToast({ message: "Reminder sent", type: "success" });
    } catch (e) {
      console.error(e);
      customToast({ message: "Error sending reminder" });
    }
    setReminding(undefined);
  };

  const emptyMap = [
    {
      title: "There are no invited talents yet",
      text: "It will be available here once you will start inviting talents.",
      button: { onClick: () => navigate("/job/invite/" + slug), label: "Invite Talent" }
    },
    {
      title: "Talent cannot be invited to inactive job",
      text: "Please make the job active if you would like to invite more talent to interview for this job"
    }
  ];

  return (
    <>
      <div className="flex gap-12 mb-6">
        <CountCard title="Total" count={metrics?.TOTAL} />
        <CountCard title="Pending" count={metrics?.INVITED} />
        <CountCard title="Completed" count={metrics?.INTERVIEWED} />
      </div>

      {count === 0 && <Empty {...emptyMap[job.active ? 0 : 1]} />}

      {!data && <LoaderPage />}

      {data && !!count && (
        <PaginatedDiv
          className="!h-auto flex-1 "
          onLimit={fetch}
          loading={fetching}
          count={count}
          currentCount={data?.length}
        >
          <Table
            data={data}
            heads={["Name", "Email Address", "Recruiter", "Status", "Date", "Invite URL", "Action"]}
            row={(i) => {
              const { id, first_name, last_name, email, createdAt, invite_url, Inviter } =
                i as Interview;
              return [
                <p>{`${first_name} ${last_name}`}</p>,
                <p className="max-w-[207px] overflow-hidden text-ellipsis">{email}</p>,
                <InitialsLogo user={Inviter.User} />,
                <InterviewStatusTag i={i} />,
                <p>{dateFormat1(createdAt)}</p>,
                <div className="flex gap-[10px]">
                  <a
                    target="_blank"
                    href={invite_url}
                    className="underling text-odfblue max-w-[200px] overflow-hidden text-ellipsis"
                  >
                    {invite_url}
                  </a>
                  <CopyButton className="w-4 h-4" text={invite_url} />
                </div>,
                <Button
                  onClick={() => remind(id)}
                  loading={reminding === id}
                  className="!text-sm !rounded-3xl !w-[141px] !px-4"
                  disabled={!!reminding && reminding !== id}
                >
                  Send reminder
                </Button>
              ];
            }}
          />
        </PaginatedDiv>
      )}
    </>
  );
};

export default InvitedTalent;
