import User from "src/types/models/User";

const styles = [
  "text-[#26993F] border-[#26993F]",
  "text-[#FF5757] border-[#FF5757]",
  "text-[#FF914D] border-[#FF914D]",
  "text-[#FF00FF] border-[#FF00FF]",
  "text-[#00FFFF] border-[#00FFFF]"
];
let next = 0;
const users: { [key in number]: string } = {};

interface IInitialsLogo {
  user: User;
}

const InitialsLogo = ({ user }: IInitialsLogo) => {
  const { user_id } = user;
  if (!users[user_id]) {
    users[user_id] = styles[next];
    next = (next + 1) % styles.length;
  }
  const style = users[user_id];
  return <InitialLogo {...user} className={style} />;
};

export const InitialLogo = ({
  first_name = "",
  last_name = "",
  className = "text-[#26993F] border-[#26993F]"
}) => (
  <p
    className={
      "w-9 h-9 p-2 rounded-full border-[1px] font-poppins font-semibold text-sm text-center shrink-0 " +
      className
    }
  >
    {first_name?.[0]?.toUpperCase() || "?"}
    {last_name?.[0]?.toUpperCase() || ""}
  </p>
);

export default InitialsLogo;
