import { compactFomat } from "./../../utils/number";
import { CURRENCY_SYMBOLS } from "src/constants";
import Job from "src/types/models/Job";

export class JobClass {
  job: Job;
  constructor(data: Job) {
    this.job = data;
  }

  salaryRangeText() {
    if (!this.job.Salary) return "";
    const { currency, rate, period } = this.job.Salary;
    const symbol = CURRENCY_SYMBOLS[currency];
    return `${symbol}${compactFomat(rate[0].value)}-${symbol}${compactFomat(rate[1].value)}(${period})`;
  }

  locationTypeText() {
    return this.job.location_type[0] + this.job.location_type.slice(1).toLowerCase();
  }

  yearsExperienceText() {
    return `${this.job.year_experience[0].value} Years - ${this.job.year_experience[1].value} Years`;
  }

  visaText() {
    return this.job.visa_sponsor ? "Yes" : "No";
  }

  experienceLevelText() {
    return this.job.experience_level + " Level";
  }

  technicalSkills() {
    return this.job.Skills.filter((s) => s.hire_job_skill.type === "Technical");
  }

  interculturalSkills() {
    return this.job.Skills.filter((s) => s.hire_job_skill.type === "Intercultural");
  }

  highlightedSkills() {
    return this.job.Skills.filter((s) => s.hire_job_skill.for_interview);
  }

  interviewDurationText() {
    return `${this.job.interview_duration} Minutes`;
  }

  interviewStatusText() {
    return `${this.job.active ? "Active" : "Inactive"}`;
  }
}
