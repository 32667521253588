import { useSearchParams } from "react-router-dom";
import { JobClass } from "src/classes/models/job";
import Job from "src/types/models/Job";
import { ReactComponent as CheckIcon } from "src/assets/svg/check2.svg";
import { useState } from "react";
import { JobSkillType } from "src/types";
import { UpdateJobParams } from "src/network/job/type";
import { ReactComponent as Plus } from "src/assets/svg/+.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal/Modal";
import { FormInput } from "src/components/Form/Input/FormInput";
import { useForm } from "react-hook-form";
import { FormCheckboxSelect } from "src/components/Form/Checkbox/FormCheckboxSelect";
import { SKILL_TYPE_OPTIONS } from "src/constants/formOptions";

type Skill = Job["Skills"][0];

const Skills = ({ job, onUpdate }: { job: Job; onUpdate: (params: UpdateJobParams) => void }) => {
  const [params] = useSearchParams();
  const editing = params.get("edit")?.includes("true");
  const j = new JobClass(job);
  return (
    <div className="p-9 w-full rounded-2xl border-[1px] border-odfblue-hue bg-white">
      {editing ? <Edit onUpdate={onUpdate} j={j} /> : <Read j={j} />}
    </div>
  );
};

// --- READ ---

const Read = ({ j }: { j: JobClass }) => {
  return (
    <>
      <Label>Technical Skills</Label>
      <SkillTags skills={j.technicalSkills()} />
      <div className="mt-12" />
      <Label>Intercultural Skills</Label>
      <SkillTags skills={j.interculturalSkills()} />
    </>
  );
};
const Label = ({ children, className = "" }) => (
  <p className={"font-poppins font-semibold text-base " + className}>{children}</p>
);
const SkillTag = ({ skill }: { skill: Skill }) => (
  <p
    className={`font-poppinst text-sm p-4 rounded-lg bg-[#F9F9F9]  ${skill.hire_job_skill.for_interview && " border-[1px] border-odfblue text-odfblue font-semibold"}`}
  >
    {skill.name}
  </p>
);
const SkillTags = ({ skills }: { skills: Skill[] }) => (
  <div className="flex flex-wrap gap-3 mt-4">
    {skills.map((s) => (
      <SkillTag skill={s} key={s.name} />
    ))}
  </div>
);

// --- EDIT ---
type ParamSkill = {
  for_interview: boolean;
  type: JobSkillType;
  name: string;
};

const defaultValues = { name: "", type: SKILL_TYPE_OPTIONS[0] };

const Edit = ({ j, onUpdate }: { j: JobClass; onUpdate: (params: UpdateJobParams) => void }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({ defaultValues });
  const base = { control, errors };
  const [add, setAdd] = useState(false);
  const [skills, setSkills] = useState<ParamSkill[]>(
    j.job.Skills.map((s) => ({
      for_interview: s.hire_job_skill.for_interview,
      type: s.hire_job_skill.type,
      name: s.name
    }))
  );
  const onMark = (name: string) => {
    const skill = skills.find((s) => s.name === name);
    if (!skill) return;
    skill.for_interview = !skill.for_interview;
    setSkills([...skills]);
  };
  const onAddSkill = (data: typeof defaultValues) => {
    const skill: ParamSkill = {
      name: data.name,
      type: data.type.value,
      for_interview: true
    };
    setSkills([...skills, skill]);
    setAdd(false);
    reset();
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onUpdate({ skills });
  };

  // To avoid nested forms
  const Wrap = add ? "div" : "form";

  return (
    <Wrap id="update_job" onSubmit={add ? undefined : onSubmit}>
      <Label>Technical Skills</Label>
      <SkillInputs onMark={onMark} skills={skills.filter((s) => s.type === "Technical")} />
      <Label className="mt-12">Intercultural Skills</Label>
      <SkillInputs onMark={onMark} skills={skills.filter((s) => s.type === "Intercultural")} />
      <Label className="mt-12">Missing skills? Add them to the evaluation criteria</Label>
      <Button
        className="mt-6"
        onClick={() => setAdd(true)}
        shapeVariant={2}
        variant="lightgray_blue"
      >
        <Plus className="mr-[10px] h-6 w-6" />
        Add a new skill
      </Button>

      {/* New skill modal */}
      <Modal className="!w-[500px]" open={add} onChange={setAdd}>
        <form onSubmit={handleSubmit(onAddSkill)}>
          <p className="my-6  text-center font-poppins text-xl font-semibold">Add a new skill</p>

          <Label className="mb-3">Enter Skill</Label>
          <FormInput
            {...base}
            rules={{
              required: "Name is required",
              validate: (value) => !skills.find((s) => s.name === value) || "Already selected"
            }}
            control={control}
            name="name"
          />

          <Label className="mb-3 mt-6">Select type of skill</Label>
          <FormCheckboxSelect
            {...base}
            rules={{ required: "Type is required" }}
            name="type"
            className="w-[300px]"
            select={{ boxes: SKILL_TYPE_OPTIONS, checkboxClassName: " !gap-3" }}
          />

          <Button type="submit" className="mt-6 mx-auto" shapeVariant={2}>
            Add new skill
          </Button>
        </form>
      </Modal>
    </Wrap>
  );
};
const SkillInputs = ({ skills, onMark }: { skills: ParamSkill[]; onMark: (s: string) => void }) => (
  <div className="flex gap-3 flex-wrap mt-6">
    {skills.map((s) => (
      <button
        type="button"
        onClick={() => onMark(s.name)}
        key={s.name}
        className={`p-4 bg-lighter-gray flex flex-wrap gap-2 rounded-lg font-poppins text-sm  items-center 
           ${s.for_interview ? "text-odfblue border-[1px]  border-odfblue" : "text-black"} `}
      >
        {s.name}
        {s.for_interview && <CheckIcon className="w-4 h-4 fill-odfblue " />}
      </button>
    ))}
  </div>
);

export default Skills;
