import { FormEmailInput } from "src/components/Form/EmailInput/FormEmailInput";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import { isEmpty } from "src/utils/object";
import { isWorkEmail } from "src/utils/constraints";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import useLoader from "src/hooks/useLoader";
import { FormCheckbox } from "src/components/Form/Checkbox/FormCheckbox";
import FormErrorSpan from "src/components/Form/FormErrorSpan";
import CompleteSteps from "src/components/CompleteSteps";

export const signUpSteps = ["Create your account", "Verification code", "Sign in using Magic Link"];

const SignUpPage = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { email: "", terms: false } });
  const _signUp = async ({ email }) => {
    const password = randomPassword(); // One time to satisfy cognito
    try {
      await signUp({ username: email, password });
      navigate("/signup/confirm", { state: { email } });
    } catch (err: any) {
      switch (err.code) {
        case "UserLambdaValidationException":
          throw new Error("You are not authorized to signup. Please contact an admin");
        case "UsernameExistsException":
          throw new Error("Email address already exists. Please try with another email.");
        default:
          throw new Error(err.message);
      }
    }
  };
  const { loading, ldng } = useLoader(_signUp);

  return (
    <Layout>
      <form onSubmit={handleSubmit(ldng)} className="w-[588px] pt-[46px]">
        <CompleteSteps current={0} steps={signUpSteps} className="mb-[72px]" />
        <p className="font-poppins text-2xl font-bold">Create your account</p>
        <p className="text-base font-poppins mt-[10px] mb-9">
          Already have an account?{" "}
          <Link className=" text-odfblue underline font-semibold" to="/">
            Login
          </Link>
        </p>

        <p className="font-poppins text-base font-semibold mb-3">Company Email*</p>
        <FormEmailInput
          name="email"
          errors={errors}
          control={control}
          rules={{
            required: "Email is required",
            validate: (value) => isWorkEmail(value) || "Please use your company email"
          }}
        />

        <div className="flex items-center mt-9">
          <FormCheckbox
            className="flex flex-col"
            name="terms"
            errors={errors}
            control={control}
            rules={{ validate: (value) => value }}
            box={{ wrapperClassName: "mr-[10px]" }}
          />
          <p className="  font-poppins text-sm text-[#8A8A8A] font-semibold text-center">
            By signing up, you agree to our
            <a
              href="https://outdefine.com/terms"
              target="_blank"
              className="text-odfblue underline mx-1"
            >
              terms of service
            </a>
            and
            <a
              href="https://outdefine.com/privacy-policy"
              target="_blank"
              className="text-odfblue underline ml-1"
            >
              privacy policy.
            </a>
          </p>
        </div>
        {errors.terms && <FormErrorSpan text={"Required"} />}

        <Button
          loading={loading}
          type="submit"
          disabled={!isEmpty(errors)}
          className="mt-9 !rounded-3xl"
        >
          Continue
        </Button>
      </form>
    </Layout>
  );
};

// Just to satisfy cognito since we are not longer using passwords
function randomPassword(): string {
  // Cognito specials and alphabet
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const special = "[]{}()?\"!@#%&/\\,><':;|_~`=+-";
  const allength = alphabet.length;
  const spelen = special.length;

  let password = "";
  const length = Math.floor(Math.random() * 3 + 8);

  let char1, char2, char3;
  for (let i = 0; i < length + 1; i++) {
    char1 = Math.floor(Math.random() * allength);
    char2 = Math.floor(Math.random() * allength);
    char3 = Math.floor(Math.random() * spelen);
    password += alphabet[char1] + alphabet[char2].toUpperCase() + special[char3];
  }

  // Shuffle
  const final = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  // In prod the password needs a number
  return (final + Math.floor(Math.random() * 9)) as string;
}

export default SignUpPage;
