import { useState } from "react";
import { ReactComponent as Arrow } from "src/assets/svg/V.svg";
import { Interview } from "src/types";
import Debbie from "src/assets/images/general/debbie.png";
import { InitialLogo } from "src/components/InitialsLogo";
import { JobClass } from "src/classes/models/job";
import Job from "src/types/models/Job";

const Conversation = ({ interview, job }: { interview: Interview; job?: Job }) => {
  const { transcript } = interview;
  const { first_name, last_name } = interview;
  if (!transcript) return null;
  const [open, setOpen] = useState(true);
  const roles = {
    assistant: "Debbie",
    user: `${first_name} ${last_name}`
  };
  return (
    <div className="w-full flex flex-col gap-6">
      <Wrap>
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="w-full flex justify-between font-semibold"
        >
          Interview Transcripts
          <Arrow className={"w-6 h-6 " + (open && "rotate-180")} />
        </button>
      </Wrap>
      {open && (
        <Wrap className="flex flex-col gap-4">
          {transcript.map((q, i) => {
            const role = roles[q.role];
            const isDebbie = role === "Debbie";
            return (
              <div key={i} className=" rounded-2xl border-[1px] border-[#ccc] bg-[#F9F9F9] p-4">
                <div className="flex items-center gap-2">
                  {isDebbie ? (
                    <img className="w-9 h-9 rounded-full" src={Debbie} />
                  ) : (
                    <InitialLogo first_name={first_name} last_name={last_name} />
                  )}
                  <p className="font-[500]">{role}</p>
                </div>
                <p className="leading-8 whitespace-pre-line mt-[10px]">
                  <BoldParagraph job={job} text={q.content.map((c) => c.text).join("\n")} />
                </p>
              </div>
            );
          })}
        </Wrap>
      )}
    </div>
  );
};

const Wrap = ({ children, className = "" }) => (
  <div className={"bg-white rounded-lg p-4 font-poppins text-base " + className}>{children}</div>
);

// This component helps to bold highlighted skills
function BoldParagraph({ text, job }: { text: string; job?: Job }) {
  // If job has not arrived yet return the same text
  if (!job) return text;
  const Job = new JobClass(job);
  const skills = Job.highlightedSkills().map((s) => s.name);

  try {
    // Escaping any special characters in skills array and converting to lowercase
    const _skills = skills.map((sentence) =>
      sentence.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase()
    );

    // Creating a regex pattern that matches any of the skills in the list, ignoring case
    const regex = new RegExp(`(${_skills.join("|")})`, "gi");

    // Splitting the text into parts based on the matching sentences
    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          _skills.includes(part.toLowerCase()) ? <strong key={index}>{part}</strong> : part
        )}
      </>
    );
  } catch (e) {
    // If something goes wrong return the normal text
    console.error("Bold paragraph error", e);
    return text as any;
  }
}

export default Conversation;
