const Tag = ({ title, text }: { title: string; text: string }) => {
  return (
    <div className="flex flex-col gap-2 font-poppins">
      <p className="text-[#8A8A8A] text-sm font-[500]">{title}</p>
      <p className="text-base font-semibold">{text}</p>
    </div>
  );
};

export default Tag;
