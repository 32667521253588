import { Interview, InterviewScore, SkillFeedback } from "src/types";
export class InterviewClass {
  interview: Interview;
  constructor(data: Interview) {
    this.interview = data;
  }

  public static defaultFeedback(): Feedback {
    return {
      general: sample(),
      resume: sample(),
      skill: sample(),
      personality: sample(),
      overall: sample(),
      skills: []
    };
  }

  getResumeFileName() {
    const { first_name, resume_presigned } = this.interview;
    if (!resume_presigned) return "";
    // Remove query params if they are there
    const link = resume_presigned.split("?")[0];
    const split = link.split(".");
    const ext = split[split.length - 1];
    return `${first_name || ""}_resume.${ext}`;
  }

  getParsedFeedback(): Feedback | undefined {
    const f = this.interview.ai_feedback;
    let _f: Feedback | undefined = undefined;
    if (!f) return _f;
    try {
      _f = InterviewClass.defaultFeedback();
      _f.general = mapFeedback(f.simple_feedback, styles[0]);
      _f.resume = mapFeedback(f.general_feedback, styles[1]);
      _f.skill = mapFeedback(f.tech_feedback, styles[2]);
      _f.personality = mapFeedback(f.personality_feedback, styles[3]);
      _f.overall = mapFeedback(f.overall_feedback, styles[4]);

      _f.skills = parseSkills(f.skill_grades);
    } catch {
      _f = undefined;
    }
    return _f;
  }
}

export interface FeedbackData {
  score: InterviewScore;
  summary: string;
  title: string;
  _score: {
    number: number;
    style: typeof scoreStyles.A;
  };
}

export interface Feedback {
  general: FeedbackData;
  resume: FeedbackData;
  skill: FeedbackData;
  personality: FeedbackData;
  overall: FeedbackData;
  skills: FeedbackData[];
}

const sample = (): FeedbackData => ({
  score: "I",
  summary: "",
  title: "",
  _score: _getScore("I")
});

const mapFeedback = (f: string, { title } = { title: "" }): FeedbackData => {
  let score: InterviewScore = "I";
  // Parse the score string and if something wrong happens assume is 0/1 for now
  try {
    const split = f.split(`Grade: `);
    score = split[1][0] as InterviewScore;
    // JIC
    if (!scoreStyles[score]) throw new Error();
    // Remove the numerical score from the feedback
    // Note: it is going to change when we update the feedback format
    f = f.split(`Numerical Grade:`)[0];
  } catch {
    /* empty */
  }
  return {
    summary: f,
    score,
    title,
    _score: _getScore(score)
  };
};

const parseSkills = (skills: SkillFeedback): FeedbackData[] => {
  const ss: FeedbackData[] = [];
  try {
    Object.entries(skills)
      .filter((e) => e[1].grade !== "I")
      .forEach((skill) => {
        ss.push({
          title: skill[0],
          summary: skill[1].feedback,
          score: skill[1].grade,
          _score: _getScore(skill[1].grade)
        });
      });
  } catch (e) {
    console.log(e);
    return [];
  }
  return ss;
};

const styles = [
  {
    title: "General"
  },
  {
    title: "Resume Related"
  },
  {
    title: "Skill Related"
  },
  {
    title: "Behavioral Related"
  },
  {
    title: "Overall Feedback"
  }
];

const ScoreNumber: { [key in InterviewScore] } = {
  A: 100,
  B: 75,
  C: 50,
  I: 25
};
const scoreStyles = {
  A: { color: "#26993F" },
  B: { color: "#FF914D" },
  C: { color: "#D32F2F" },
  I: { color: "#8A8A8A" }
};

const _getScore = (score: InterviewScore) => ({
  number: ScoreNumber[score],
  style: scoreStyles[score]
});
