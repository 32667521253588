import Layout from "src/router/AuthenticatedRouter/Layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "src/components/Button";
import { ReactComponent as Plus } from "src/assets/svg/+.svg";
import Tabs from "src/components/Tabs";
import InvitedTalent from "./tabs/InvitedTalent";
import Job from "src/types/models/Job";
import React, { useEffect, useState } from "react";
import { getJob } from "src/network/job";
import JobDetail from "./tabs/JobDetail";
import LoaderPage from "src/components/Loader/LoaderPage";
import Skills from "./tabs/Skills";
import { getInterviewMetrics } from "src/network/interview";
import { InterviewMetrics } from "src/network/interview/type";
import InterviewedTalent from "./tabs/InterviewdTalent";
import useUpdate from "./hook/useUpdate";
import Settings from "./tabs/Settings";
import EditButton from "src/components/EditButton";
import useSlugParam from "src/hooks/useSlug";

export type JobPageTab =
  | "Invited Talent"
  | "Interviewed Talent"
  | "Job Description"
  | "Required Skills"
  | "Settings";

export const tabs: JobPageTab[] = [
  "Invited Talent",
  "Interviewed Talent",
  "Job Description",
  "Required Skills",
  "Settings"
];

const JobDetailPage = () => {
  const navigate = useNavigate();
  const { id, slug } = useSlugParam();
  const [job, setJob] = useState<Job>();
  const [params] = useSearchParams();
  const { update } = useUpdate(setJob);
  const tab = params.get("tab") || tabs[0];
  const [metrics, setMetrics] = useState<InterviewMetrics>();

  // Initial Fetch
  useEffect(() => {
    if (!id) return;
    (async () => {
      const job = await getJob({ id: Number(id) });
      setJob(job);
      const m = await getInterviewMetrics({ job_id: Number(id) });
      setMetrics(m);
    })();
  }, [id]);

  let rightChildren;
  switch (tab) {
    case tabs[0]:
    case tabs[1]:
      rightChildren = (
        <Button
          onClick={() => navigate("/job/invite/" + slug)}
          shapeVariant={2}
          variant="white_blue"
        >
          <Plus className="mr-[10px] h-6 w-6" /> Invite Talent
        </Button>
      );
      if (!job?.active) rightChildren = undefined;
      break;
    default:
      rightChildren = <EditButton form="update_job" />;
      break;
  }

  let title: React.ReactNode = "Loading...";
  if (job)
    title = (
      <p className="flex gap-6 items-center">
        {job.job_title} {!job.active && <InactiveBadge />}
      </p>
    );

  return (
    <Layout
      title={title}
      className={`flex flex-col ${[tabs[0], tabs[1]].includes(tab as JobPageTab) && "!overflow-hidden"}  `}
      back="/"
      rightChildren={rightChildren}
    >
      {job ? (
        <Tabs
          className="mb-6"
          tabs={tabs}
          withSearchParam
          contents={[
            <InvitedTalent metrics={metrics} job={job} />,
            <InterviewedTalent metrics={metrics} />,
            <JobDetail onUpdate={update} job={job} />,
            <Skills onUpdate={update} job={job} />,
            <Settings onUpdate={update} job={job} />
          ]}
        />
      ) : (
        <LoaderPage />
      )}
    </Layout>
  );
};

const InactiveBadge = () => (
  <p className="font-poppins text-sm text-error font-semibold p-2 border-[1px] border-error  rounded-lg">
    Inactive
  </p>
);

export default JobDetailPage;
