import Chart from "react-apexcharts";

import { ReactComponent as Docs } from "src/assets/svg/document-management.svg";
import { Interview } from "src/types";
import Tag from "../components/Tag";
import { dateFormat1 } from "src/utils/dates/formats";
import { Feedback } from "src/classes/models/interview";
import FeedbackCard from "../components/FeedbackCard";
import SkillsFeedback from "../components/SkillsFeedback";
import Button from "../../../../components/Button";
import { isProduction } from "../../../../config/env";
import { generateFeedback } from "../../../../network/interview";
import useLoader from "../../../../hooks/useLoader";
import { JobClass } from "src/classes/models/job";

const Report = ({ interview, feedback }: { interview: Interview; feedback?: Feedback }) => {
  const { loading, ldng } = useLoader(generateFeedback, {
    successMsg: "Feedback queued for generation",
    errorMsg: "Error generating feedback"
  });
  if (!feedback) return <Pending />;
  const { general, personality, resume, skill, overall, skills } = feedback;
  const { email, Job, date_completed } = interview;
  const _Job = new JobClass(Job);

  return (
    <div className="max-w-[1110px] mx-auto mt-10">
      <div className="bg-white rounded-xl  border-[0.1px] border-odf-light flex  p-9 mb-6">
        {/* Tags */}
        <div className="flex flex-wrap gap-y-9 gap-x-[44px] w-[624px] h-fit">
          <Tag title="Email Address" text={email} />
          <Tag title="Job Role" text={Job.job_title} />
          {date_completed && <Tag title="Date of interview" text={dateFormat1(date_completed)} />}
          <Tag title="Interview Duration" text={_Job.interviewDurationText()} />
        </div>
        {/* Overview */}
        <div className="bg-white rounded-3xl border-[1px] border-odfblue border-opacity-65 w-[180px] p-4  ml-auto relative">
          <div className={`flex flex-col justify-center items-center`}>
            <Chart
              type="radialBar"
              width="100%"
              height="75%"
              series={[overall._score.number]}
              options={{
                chart: { type: "radialBar" },
                fill: { colors: [overall._score.style.color] },
                labels: [overall.score],
                plotOptions: {
                  radialBar: {
                    dataLabels: {
                      name: {
                        show: true,
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "36px",
                        fontWeight: 600,
                        offsetY: 12
                      },
                      value: {
                        show: false
                      }
                    },
                    hollow: {
                      size: "50%"
                    }
                  }
                }
              }}
            />
          </div>
          <p className="font-poppins text-2xl font-semibold text-odfblue text-center  mt-2">
            Overall Hire Score
          </p>
          {!isProduction && (
            <Button
              loading={loading}
              onClick={() => ldng({ id: interview.id })}
              className="absolute !w-fit -top-4 -right-10 px-2"
            >
              Rgn
            </Button>
          )}
        </div>
      </div>
      {/* Overall */}
      <FeedbackCard feedback={overall} />

      {/* Grid */}
      <div className="grid grid-cols-2 gap-10 mt-9">
        <FeedbackCard feedback={general} />
        <FeedbackCard feedback={resume} />
        <FeedbackCard feedback={skill} />
        <FeedbackCard feedback={personality} />
      </div>

      {/* Skills feedback */}
      <SkillsFeedback feedback={skills} />
    </div>
  );
};

const Pending = () => (
  <div className="max-w-[400px] mx-auto mt-20 flex flex-col items-center text-center">
    <Docs />
    <p className="font-semibold font-poppins text-xl mt-[50px]">Feedback is being generated</p>
    <p className="font-poppins text-base font-[500] text-[#8A8A8A] mt-[10px] ">
      We are currently processing feedback for this candidate. Please try again in a little while.
    </p>
  </div>
);

export default Report;
