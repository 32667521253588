import { Interview } from "src/types";
import Conversation from "../components/Conversation";
import { ReactComponent as NoComment } from "src/assets/svg/no-communication.svg";
import Job from "src/types/models/Job";

const Recording = ({ interview, job }: { interview: Interview; job?: Job }) => {
  const { transcript } = interview;
  //NOTE: Commented until we actually have urls
  const video_url = "";
  if (!transcript)
    return (
      <div className="mx-auto flex flex-col items-center gap-6 pt-[120px]">
        <NoComment className="w-[220px] h-[220px]" />
        <p className="font-poppins text-xl font-semibold">No Resume Found</p>
      </div>
    );

  return (
    <div className="mt-9">
      {video_url && (
        <div className="mb-12">
          <p className="bg-[white] rounded-lg p-4 font-poppins font-semibold text-base mb-6">
            Video Recording{" "}
          </p>
          <iframe className="rounded-xl w-[1000px] h-[400px] bg-black" src={video_url} />
        </div>
      )}
      <Conversation interview={interview} job={job} />
    </div>
  );
};

export default Recording;
