import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { customToast } from "src/utils/toast";
import Input from "src/components/Form/Input/Input";
import { sendMagicLink } from "src/network/auth";
import { confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import Button from "src/components/Button";
import useLoader from "src/hooks/useLoader";
import CompleteSteps from "src/components/CompleteSteps";
import { signUpSteps } from ".";

const VerifyPage = () => {
  const [code, setCode] = useState<string>("");
  const { state } = useLocation();
  const { email } = state;
  const navigate = useNavigate();
  const handleConfirm = async () => {
    try {
      // Confirm Code
      await confirmSignUp({ username: email, confirmationCode: code });

      customToast({ message: "Thanks for confirming your email.", type: "success" });
      await sendMagicLink(email);
      navigate("/linksent", { state: { email, from: "signup" } });
      customToast({ message: "Magic link sent", type: "success" });
    } catch (e: any) {
      console.error(JSON.stringify(e));
      switch (e.name) {
        case "CodeMismatchException":
          throw new Error("Invalid verification code.");
        case "NotAuthorizedException":
          throw new Error("Your email is already confirmed.");
        default:
          throw new Error("Unknown error.");
      }
    }
  };
  const handleResend = async () => {
    try {
      await resendSignUpCode({ username: email });
    } catch (err: any) {
      console.error("err: ", err);
      throw new Error(err?.name);
    }
  };
  const { ldng: confirm, loading: confirming } = useLoader(handleConfirm);
  const { ldng: resend, loading: resending } = useLoader(handleResend, {
    successMsg: "Resent verification code. Please check your inbox."
  });

  return (
    <Layout>
      <div className="w-[588px] pt-[46px]">
        <CompleteSteps current={1} steps={signUpSteps} className="mb-[72px]" />
        <p className="font-poppins text-2xl font-bold">Verify your email</p>
        <p className="text-base font-poppins mt-[10px] mb-9">
          Verify your email using the 6 digit number that was sent. Didn`t receive one? Receive
          another 6 digit code when you press “resend”.
        </p>

        <p className="font-poppins text-base font-semibold mb-3">6 digit verification code*</p>
        <Input
          name="code"
          type="text"
          placeholder="123456"
          onChange={(e) => setCode(e.target.value)}
          value={code}
          className="mt-[12px] h-[48px] p-[16px]"
          autoComplete="off"
          maxLength={6}
          minLength={6}
        />

        <Button
          onClick={confirm}
          disabled={!code}
          loading={confirming}
          type="submit"
          className="mt-9 !rounded-3xl"
        >
          Finish Sign Up
        </Button>

        <Button
          onClick={resend}
          loading={resending}
          className="!rounded-3xl mt-[20px]"
          variant="white_blue"
        >
          Resend Code
        </Button>
      </div>
    </Layout>
  );
};

export default VerifyPage;
