interface ICompleteSteps {
  steps: string[];
  current: number;
  className?: string;
}

const CompleteSteps = ({ steps, current, className = "" }: ICompleteSteps) => {
  return (
    <div className={"flex w-full " + className}>
      {steps.map((s, i) => {
        const curr = current === i;
        const completed = current > i;
        const passed = completed || curr;
        const last = i === steps.length - 1;
        return (
          <div key={s} className={`flex text-center ${last ? "w-fit" : "w-full"} `}>
            {/* Step */}
            <div className="w-[110px]">
              <p
                className={`mx-auto w-5 h-5 text-white rounded-full text-xs flex items-center justify-center ${passed ? "bg-odfblue" : "bg-[#ccc]"}  `}
              >
                {completed ? "✓" : i + 1}
              </p>
              <p
                className={`font-poppins text-base font-[500]  mt-2 ${passed ? "text-odfblue" : "text-[#ccc]"}`}
              >
                {s}
              </p>
            </div>
            {/* Right side line */}
            {!last && (
              <div className={`flex-1 h-[1px] ${completed ? "bg-odfblue" : "bg-[#ccc]"}`} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CompleteSteps;
