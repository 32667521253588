const { REACT_APP_NODE_ENV, REACT_APP_MAINTENANCE } = process.env;

export const isProduction = REACT_APP_NODE_ENV === "production";
export const isDevelopment = REACT_APP_NODE_ENV === "development" || !REACT_APP_NODE_ENV;
export const isLocal =
  window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

export const onMaintenance = REACT_APP_MAINTENANCE === "ON";

export const BACKEND_AI_URL = isDevelopment
  ? "https://w69tk4fhza.execute-api.us-west-1.amazonaws.com/dev"
  : "https://6qvhz8gm69.execute-api.us-east-1.amazonaws.com/prod";
