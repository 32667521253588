import Tabs from "src/components/Tabs";
import Layout from "src/router/AuthenticatedRouter/Layout";
import Profile from "./Tabs/Profile";
import Users from "./Tabs/Users";
import { useSearchParams } from "react-router-dom";
import Button from "src/components/Button";
import EditButton from "src/components/EditButton";
import { ReactComponent as Plus } from "src/assets/svg/+3.svg";
import { useEffect, useState } from "react";
import InviteModal from "./Components/InviteModal";
import { ClientInvite } from "src/types/models/ClientInvite";
import { getInvites } from "src/network/client_invite";
import useLoader from "src/hooks/useLoader";

export type SettingTab = "Profile" | "Users";

export const tabs: SettingTab[] = ["Profile", "Users"];

const SettingsPage = () => {
  const [open, setOpen] = useState(false);
  const [invites, setInvites] = useState<ClientInvite[]>();
  const [params] = useSearchParams();
  const tab = params.get("tab") || tabs[0];
  const fetchInvites = async () => {
    const invites = await getInvites();
    setInvites(invites);
  };
  const { ldng } = useLoader(fetchInvites, { errorMsg: "Error fetching invites" });

  useEffect(() => {
    ldng();
  }, []);

  let rightChildren;
  switch (tab) {
    case tabs[0]:
      rightChildren = <EditButton form="client_form" />;
      break;
    case tabs[1]:
      rightChildren = (
        <Button disabled={!invites} onClick={() => setOpen(true)} shapeVariant={2}>
          <Plus className="mr-[10px] h-6 w-6" /> Invite Recruiter
        </Button>
      );

      break;
    default:
      break;
  }
  return (
    <Layout title={`Settings`} className="" rightChildren={rightChildren}>
      <Tabs
        withSearchParam
        tabs={tabs}
        contents={[<Profile />, <Users setInvites={setInvites} invites={invites} />]}
      />

      {open && <InviteModal setInvites={setInvites} invites={invites || []} onChange={setOpen} />}
    </Layout>
  );
};

export default SettingsPage;
