import Chart from "react-apexcharts";
import { FeedbackData } from "src/classes/models/interview";

interface IFeedbackCard {
  feedback: FeedbackData;
}

const FeedbackCard = ({ feedback }: IFeedbackCard) => {
  const { score, _score } = feedback;
  return (
    <div className="bg-white rounded-xl overflow-hidden ">
      <div className="flex items-center justify-between px-4 py-2 border-l-odfblue border-l-4 border-b-[#ccc] border-b-[1px] ">
        <p className="font-poppins text-xl font-semibold ">{feedback.title}</p>
        <div className=" w-[66px] h-[66px] overflow-visible -translate-y-[15px] -translate-x-[10px]">
          <Chart
            type="radialBar"
            width="150%"
            height="150%"
            series={[_score.number]}
            options={{
              labels: [score],
              chart: { type: "radialBar" },
              fill: { colors: [_score.style.color] },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      show: true,
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "28px",
                      fontWeight: 600,
                      offsetY: 8
                    },
                    value: {
                      show: false
                    }
                  },
                  hollow: {
                    size: "45%"
                  }
                }
              }
            }}
          />
        </div>
      </div>

      <div className="w-full rounded-lg bg-white p-4 font-poppins text-sm mt-3 whitespace-pre-line leading-[32px]">
        {feedback.summary}
      </div>
    </div>
  );
};

export default FeedbackCard;
