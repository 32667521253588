import { useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import Button from "src/components/Button";
import CompleteSteps from "src/components/CompleteSteps";
import { FormCityInput } from "src/components/Form/CityInput/FormCityInput";
import { FormDropdownSelect } from "src/components/Form/DropdownSelect/FormDropdownSelect";
import {
  nmbOfEmplOptions,
  usDialCodeOption,
  usaCountryOption
} from "src/components/Form/DropdownSelect/Options";
import { FormInput } from "src/components/Form/Input/FormInput";
import { FormPhoneInput } from "src/components/Form/PhoneInput/FormPhoneInput";
import { IPhone } from "src/components/Form/PhoneInput/PhoneInput";
import Logo from "src/components/Logo/Logo";
import useLoader from "src/hooks/useLoader";
import { clientSignUp } from "src/network/auth";
import { login } from "src/redux/slices/auth";
import { useAppDispatch } from "src/redux/store";
import { noSpaceAtStartNorEndRegex, urlRegex } from "src/utils/constraints";

const steps = ["Company Information", "Personal Information"];

// Dropdown Options

const defaultValues = {
  name: "",
  website: "",
  country: usaCountryOption,
  city: "",
  number_of_employees: nmbOfEmplOptions[0],
  role: "",

  // User
  first_name: "",
  last_name: "",
  phone: { dial: usDialCodeOption } as IPhone | undefined
};

// The purpose of this page is to finish the sign up for outdefine once the cognito part is complete
const ClientSignUpPage = () => {
  const [step, setStep] = useState(1);
  const form = useForm({ defaultValues });
  return (
    <div className="w-full py-10 flex flex-col items-center justify-center">
      <Logo className="w-[140px] mb-12" />
      <CompleteSteps current={step - 1} steps={steps} className="mb-[72px] !w-[350px] mx-auto" />
      {step === 1 && <Part1 setStep={setStep} form={form} />}
      {step === 2 && <Part2 setStep={setStep} form={form} />}
    </div>
  );
};

interface IPart {
  form: UseFormReturn<typeof defaultValues>;
  setStep: (_: number) => void;
}

const Part1 = ({ form, setStep }: IPart) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = form;
  const base = { control, errors };
  const className = "mb-9";
  return (
    <>
      <p className="font-poppins text-2xl font-semibold mb-9">
        Tell us about your company to get started
      </p>
      <Card handleSubmit={handleSubmit(() => setStep(2))}>
        <Label>Company Name*</Label>
        <FormInput
          {...base}
          name="name"
          className={className}
          input={{
            placeholder: "Enter company name"
          }}
          rules={{
            required: "Name is required",
            validate: (value) =>
              noSpaceAtStartNorEndRegex.test(value) || "Invalid white space usage"
          }}
        />

        <Label>Headquarters*</Label>
        <div className="mb-9 flex gap-[60px]">
          <FormDropdownSelect
            name="country"
            {...base}
            select={{
              className: " w-[220px]",
              _options: "countries",
              placeholder: "Select country"
            }}
          />

          <FormCityInput
            {...base}
            name="city"
            cityinput={{
              country: watch("country")?.value,
              className: " !w-[220px]",
              placeholder: "Enter City name"
            }}
            rules={{ required: "City is required" }}
          />
        </div>

        <Label>Number of Employees*</Label>
        <FormDropdownSelect
          name="number_of_employees"
          {...base}
          select={{
            _options: "number_of_employees",
            placeholder: "Select total number of employees"
          }}
          className={className}
        />

        <Label>Company Website*</Label>
        <FormInput
          {...base}
          className="mb-6 "
          name="website"
          input={{
            className: "underline placeholder-shown:no-underline",
            placeholder: "Enter the link to company website(for eg. www.google.com)"
          }}
          rules={{
            required: "Website is required",
            validate: (value) => urlRegex.test(value) || "Invalid url format"
          }}
        />

        <Button type="submit" className="!w-fit h-[56px] ml-auto !rounded-3xl !px-6">
          Next
        </Button>
      </Card>
    </>
  );
};

const Part2 = ({ form, setStep }: IPart) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = form;
  const base = { control, errors };
  const submit = async (data: typeof defaultValues) => {
    const _data = {
      ...data,
      country: data.country?.value,
      number_of_employees: data.number_of_employees.value,
      // Is both parts of the phone or nothing (also avoid empty string)
      phone_number: data?.phone?.phone || undefined,
      dial_code: data?.phone?.phone ? data.phone?.dial?.value : undefined
    };
    delete _data.phone;
    await clientSignUp(_data);
    await dispatch(login());
  };
  const { ldng, loading } = useLoader(submit);

  const className = "mb-9";

  return (
    <>
      <p className="font-poppins text-2xl font-semibold mb-9">Tell us about yourself</p>
      <Card handleSubmit={handleSubmit(ldng)}>
        <Label>First Name*</Label>
        <FormInput
          {...base}
          name="first_name"
          className={className}
          input={{
            placeholder: "Enter your first name"
          }}
          rules={{
            required: "Field is required",
            minLength: {
              value: 2,
              message: "First name must be at least 2 characters"
            },
            validate: (value) => noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
          }}
        />

        <Label>Last Name*</Label>
        <FormInput
          {...base}
          name="last_name"
          className={className}
          input={{
            placeholder: "Enter your last name"
          }}
          rules={{
            required: "Field is required",
            minLength: {
              value: 2,
              message: "Last name must be at least 2 characters"
            },
            validate: (value) => noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
          }}
        />

        <Label>Role at Company*</Label>
        <FormInput
          {...base}
          name="role"
          className={className}
          input={{
            placeholder: "Enter your role at company"
          }}
          rules={{
            required: "Role is required",
            minLength: {
              value: 2,
              message: "must be at least 2 characters"
            },
            validate: (value) => noSpaceAtStartNorEndRegex.test(value) || "Invalid whitespace usage"
          }}
        />

        <Label>Phone Number</Label>
        <FormPhoneInput {...base} name="phone" className="mb-6" />

        <div className="w-fit ml-auto flex gap-6">
          <Button
            variant="white_blue"
            onClick={() => setStep(1)}
            className="!w-fit h-[56px] !rounded-3xl !px-6"
          >
            Back
          </Button>

          <Button
            loading={loading}
            type="submit"
            className="!w-[107px] h-[56px] !rounded-3xl !px-6"
          >
            Submit
          </Button>
        </div>
      </Card>
    </>
  );
};

const Label = ({ children }) => (
  <p className="font-poppins text-base font-semibold mb-3">{children}</p>
);

const Card = ({ children, handleSubmit }) => (
  <form
    onSubmit={handleSubmit}
    className="w-[548px] bg-white rounded-3xl p-6 border-[1px] border-odfblue"
  >
    {children}
  </form>
);

export default ClientSignUpPage;
